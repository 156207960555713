import React, { useEffect, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Signup from "../src/components/auth/Signup";
import Home from "../src/components/Homepage";
import Login from "../src/components/auth/Login";
import HelpSupport from "../src/components/Helpsupport";
import Settings from "../src/components/Settings";
import ForgotPassword from "../src/components/auth/Forgotpass";
import Reservations from "../src/components/Reservation/index";
import Vendors from "../src/components/Vendors/index";
import CompareVendor from "./components/CompareVendor/CompareVendor";
import { useDispatch, useSelector } from "react-redux";
import { setUseJourneyVendor } from "./state";
import ContactVendor from "./components/ContactVendor";

function App() {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => !!state.token);
  const userType = useSelector((state) => state.user_type);
  const useJourneyVendor = useSelector((state) => !!state.useJourneyVendor);
  const queryParameters = useMemo(() => new URLSearchParams(window.location.search), []);
  const vendorId = useMemo(() => queryParameters.get("vi"), [queryParameters]);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (vendorId) {
      dispatch(setUseJourneyVendor(vendorId));
    }
  }, [vendorId, dispatch]);

  const getRedirectPath = () => {
    if (!isAuthenticated) return <Navigate to="/" />;
    return userType === "guest" ? <Home /> : <Vendors />;
  };
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated
                ? userType === "guest"
                  ? <Navigate to="/home" />
                  : <Navigate to="/vendors" />
                : <Login />
            }
          />
          <Route
            path="/signup"
            element={
              isAuthenticated
                ? userType === "guest"
                  ? <Navigate to="/home" />
                  : <Navigate to="/vendors" />
                : <Signup />
            }
          />
          <Route path="/forgotpassword" element={<ForgotPassword />} />

          {useJourneyVendor == null ? (
            <Route path="/from-vendor" element={isAuthenticated ? <Home /> : <Navigate to="/" />} />
          ) : (
            <Route path="/home" element={getRedirectPath()} />
          )}

          <Route path="/vendors" element={getRedirectPath()} />
          <Route path="/helpsupport" element={isAuthenticated ? <HelpSupport /> : <Navigate to="/" />} />
          <Route path="/ContactVendor" element={isAuthenticated ? <ContactVendor /> : <Navigate to="/" />} />
          <Route path="/CompareVendor" element={isAuthenticated ? <CompareVendor /> : <Navigate to="/" />} />
          <Route path="/settings" element={isAuthenticated ? <Settings /> : <Navigate to="/" />} />
          <Route path="/reservations" element={isAuthenticated ? <Reservations /> : <Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
