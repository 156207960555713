import React from 'react';

const SkeletonCard = () => {
    return (
        <div className="border p-4 rounded-lg shadow-lg flex flex-col items-center animate-pulse">
            <div className="w-32 h-32 bg-gray-300 rounded-md mb-4"></div>
            <div className="w-24 h-6 bg-gray-300 rounded mb-2"></div>
            <div className="w-full h-4 bg-gray-300 rounded"></div>
            <div className="w-full h-4 bg-gray-300 rounded mt-2"></div>
        </div>
    );
};

const VendorList = ({ vendors, selectedVendors, handleVendorSelection, loading }) => {
    return (
        <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 w-full mx-3 p-4">
            {loading
                ? Array(4).fill(<SkeletonCard />)
                : vendors.map((card) => (
                    <div
                        key={card.id}
                        className={`relative border p-4 rounded-lg cursor-pointer transition-transform duration-300 transform hover:scale-105 shadow-lg flex flex-col justify-between 
                ${selectedVendors.includes(card) ? 'bg-blue-500 text-white' : 'bg-white'}`}
                        onClick={() => handleVendorSelection(card)}
                    >
                        <div className="flex flex-col items-center">
                            <img
                                src={card.logo || require('../../assets/Png/placeholderImg.jpeg')}
                                alt={card.title}
                                className="w-32 h-32 object-cover rounded-md mb-4 shadow-md"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = require('../../assets/Png/placeholderImg.jpeg');
                                }}
                            />
                            <h2 className="text-lg font-semibold mb-2">{card.title}</h2>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: card?.description ?? '',
                                }}
                            ></div>
                        </div>
                        {selectedVendors.includes(card) && (
                            <div className="absolute top-0 right-0 bg-green-500 text-white p-1 rounded-se-lg rounded-es-lg flex items-center justify-center">
                                <p>✓</p>
                            </div>
                        )}
                    </div>
                ))}
        </div>
    );
};

export default VendorList;
