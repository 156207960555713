import React, { useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { post } from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner";
import Close from "../../assets/Png/Closemodal.png";
import moment from "moment";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
export function PgAgreementModal({
  setAgreementCheck,
  showModal,
  setShowModal,
  setSignature,
  isFromSummary,
  onResponseSignatureData,
  data,
  is_18_select = false
}) {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [showFullText, setShowFullText] = useState(false);
  const [signatureSubmitted, setSignatureSubmitted] = useState(false);
  const [onsubmit, setOnSubmit] = useState(null);
  const [responseData, setResponseData] = useState(data);
  const [loader, setLoader] = useState(false);
  const [isSignaturePresent, setIsSignaturePresent] = useState(false);
  const [responseSignatureData, setResponseSignatureData] = useState(0);
  const [setImage, setShowImage] = useState(null);
  const signatureCanvasRef = useRef(null);
  const SignatureDataResponse = localStorage.getItem("apiData");

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  // onResponseSignatureData(responseSignatureData);

  const clear = () => {
    signatureCanvasRef.current.clear();
  };

  const handleSubmission = async (values) => {
    let minors = [];

    minors.push({
      child: {
        first_name: values.first_name_1,
        last_name: values.last_name_1,
        dob: values.dob_1,
        relation: values.relation_1,
      },
    });

    if (values.first_name_2 && values.last_name_2 && values.dob_2 && values.relation_2) {
      minors.push({
        child: {
          first_name: values.first_name_2,
          last_name: values.last_name_2,
          dob: values.dob_2,
          relation: values.relation_2,
        },
      });
    }

    if (values.first_name_3 && values.last_name_3 && values.dob_3 && values.relation_3) {
      minors.push({
        child: {
          first_name: values.first_name_3,
          last_name: values.last_name_3,
          dob: values.dob_3,
          relation: values.relation_3,
        },
      });
    }
    if (values.first_name_4 && values.last_name_4 && values.dob_4 && values.relation_4) {
      minors.push({
        child: {
          first_name: values.first_name_4,
          last_name: values.last_name_4,
          dob: values.dob_4,
          relation: values.relation_4,
        },
      });
    }

    try {
      setLoader(true);

      if (signatureCanvasRef.current?.isEmpty()) {
        return;
      }
      const img = signatureCanvasRef.current.toDataURL();
      const signatureFile = dataURLtoFile(img, "signature.png");
      const formdata = new FormData();
      formdata.append("signature", signatureFile);
      formdata.append("agreement_id", data.id);
      formdata.append("name", `${user.first_name} ${user.last_name}`);
      formdata.append("dob", values.dob);
      formdata.append("phone", values.phone);
      formdata.append("address", values.address);
      formdata.append("city", values.city);
      formdata.append("state", values.state);
      formdata.append("zip", values.zip);
      formdata.append("email", user.email);
      formdata.append("date", values.todayDate);
      [0, 1, 2, 3].forEach((i, ind) => {
        const firstName = values[`first_name_${i + 1}`];
        const lastName = values[`last_name_${i + 1}`];
        const dob = values[`dob_${i + 1}`];
        const relation = values[`relation_${i + 1}`];
        if (firstName && lastName && dob && relation) {
          formdata.append(`childs[${i}][name]`, `${firstName} ${lastName}`);
          formdata.append(`childs[${i}][dob]`, dob);
          formdata.append(`childs[${i}][relation]`, relation);
        }
      });

      const res = await post(`/booking/parental-agreement`, formdata, {}, Token);
      if ("response" in res) {
        const response = res.response.data.parental_form.id;
        onResponseSignatureData(response);
        // setResponseSignatureData(response);
        setSignatureSubmitted(true);
      }
      if (loader) {
        return (
          <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
            <Spinner className="spinner" isLoading={loader} />
          </div>
        );
      } else {
        setShowModal(false);
        setAgreementCheck(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleSubmissionUser = async (values) => {

    try {
      setLoader(true);

      if (signatureCanvasRef.current?.isEmpty()) {
        return;
      }
      const img = signatureCanvasRef.current.toDataURL();
      const signatureFile = dataURLtoFile(img, "signature.png");
      const formdata = new FormData();
      formdata.append("signature", signatureFile);
      formdata.append("agreement_id", data.id);
      formdata.append("name", `${user.first_name} ${user.last_name}`);
      formdata.append("dob", values.dob);
      formdata.append("phone", values.phone);
      formdata.append("address", values.address);
      formdata.append("city", values.city);
      formdata.append("state", values.state);
      formdata.append("zip", values.zip);
      formdata.append("email", user.email);
      formdata.append("date", values.todayDate);
      const res = await post(`/booking/parental-agreement`, formdata, {}, Token);
      if ("response" in res) {
        const response = res.response.data.parental_form.id;
        onResponseSignatureData(response);
        // setResponseSignatureData(response);
        setSignatureSubmitted(true);
      }
      if (loader) {
        return (
          <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
            <Spinner className="spinner" isLoading={loader} />
          </div>
        );
      } else {
        setShowModal(false);
        setAgreementCheck(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const validationSchema = Yup.object({
    // Required fields for Minor #1
    first_name_1: Yup.string().required("First Name is required for Minor #1"),
    last_name_1: Yup.string().required("Last Name is required for Minor #1"),
    dob_1: Yup.string().required("Date of Birth is required for Minor #1"),
    relation_1: Yup.string().required("Relation is required for Minor #1"),
    signature: Yup.string().required("Signature is required"),
    dob: Yup.date().required("Date of birth is required"),
    first_name_2: Yup.string(),
    last_name_2: Yup.string(),
    dob_2: Yup.string(),
    relation_2: Yup.string(),
    minor_2: Yup.object().test(
      'complete-minor-2',
      "Complete all fields for Minor #2",
      function (value) {
        const { first_name_2, last_name_2, dob_2, relation_2 } = this.parent;
        const isAnyFieldFilled = first_name_2 || last_name_2 || dob_2 || relation_2;
        const areAllFieldsFilled = first_name_2 && last_name_2 && dob_2 && relation_2;
        return !isAnyFieldFilled || areAllFieldsFilled;
      }
    ),
    first_name_3: Yup.string(),
    last_name_3: Yup.string(),
    dob_3: Yup.string(),
    relation_3: Yup.string(),
    minor_3: Yup.object().test(
      'complete-minor-3',
      "Complete all fields for Minor #3",
      function (value) {
        const { first_name_3, last_name_3, dob_3, relation_3 } = this.parent;
        const isAnyFieldFilled = first_name_3 || last_name_3 || dob_3 || relation_3;
        const areAllFieldsFilled = first_name_3 && last_name_3 && dob_3 && relation_3;
        // If any field is filled, all must be filled
        return !isAnyFieldFilled || areAllFieldsFilled;
      }
    ),
    first_name_4: Yup.string(),
    last_name_4: Yup.string(),
    dob_4: Yup.string(),
    relation_4: Yup.string(),
    minor_4: Yup.object().test(
      'complete-minor-4',
      "Complete all fields for Minor #4",
      function (value) {
        const { first_name_4, last_name_4, dob_4, relation_4 } = this.parent;
        const isAnyFieldFilled = first_name_4 || last_name_4 || dob_4 || relation_4;
        const areAllFieldsFilled = first_name_4 && last_name_4 && dob_4 && relation_4;
        // If any field is filled, all must be filled
        return !isAnyFieldFilled || areAllFieldsFilled;
      }
    ),
  });
  const validationSchemaUser = Yup.object({
    dob: Yup.string().required(),
  });
  const minorForm = () => {
    return (
      <Formik
        initialValues={{
          first_name_1: "",
          last_name_1: "",
          dob_1: "",
          relation_1: "",
          first_name_2: "",
          last_name_2: "",
          dob_2: "",
          relation_2: "",
          first_name_3: "",
          last_name_3: "",
          dob_3: "",
          relation_3: "",
          first_name_4: "",
          last_name_4: "",
          dob_4: "",
          relation_4: "",
          signature: null,
          fullName: `${user.first_name} ${user.last_name}`,
          address: user.address1,
          city: user.city,
          state: user.state,
          zip: user.zip,
          phone: user.phone,
          dob: user.phone || "",
          todayDate: moment(new Date()).format("MM/DD/YYYY"),
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmission(values)}
      >
        {({ errors, touched, handleBlur, values, setFieldValue, handleChange }) => (
          <Form>
            <div className="relative z-0 w-full mb-5 group mt-4">
              <input
                type="text"
                name="fullName"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none font-semibold dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                disabled
                value={values.fullName}
              />
              <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                Parent name/legal guardian (print):
              </label>
            </div>

            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="address"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none font-semibold dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                disabled
                value={values.address}
              />
              <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                Address
              </label>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="city"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.city}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  City
                </label>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="state"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.state}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  State
                </label>
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="zip"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.zip}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  Zip
                </label>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="tel"
                  name="phone"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.phone}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  Phone number
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="date"
                  name="dob"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  value={values.dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.dob && touched.dob && (
                  <div className="text-red-500 text-sm">{errors.dob}</div>
                )}
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  Date of birth
                </label>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="todayDate"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.todayDate}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  Today's date
                </label>
              </div>
            </div>
            {MINORS.map((minor, index) => (
              <div key={index}>
                <h3 className="font-bold">{minor.title}</h3>
                <div className="grid md:grid-cols-2 md:gap-6 mt-6">
                  {minor.fields.map((field) => (
                    <div key={field.id} className="relative z-0 w-full mb-5 group">
                      <Field
                        type={field.type}
                        id={field.id}
                        name={field.id}
                        onBlur={handleBlur} // Ensure Formik sets the touched state
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor={field.id}
                        className="z-10 peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        {field.label}
                      </label>
                      {errors[field.id] && touched[field.id] && (
                        <ErrorMessage
                          name={field.id}
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      )}
                    </div>
                  ))}
                </div>
                {/* Display minor_2 error at the top of the section if it exists */}
                {index === 1 && errors.minor_2 && (
                  <div className="text-red-500 text-sm">
                    {errors.minor_2}
                  </div>
                )}
                {index === 2 && errors.minor_3 && (
                  <div className="text-red-500 text-sm">
                    {errors.minor_3}
                  </div>
                )}
                {index === 3 && errors.minor_4 && (
                  <div className="text-red-500 text-sm">
                    {errors.minor_4}
                  </div>
                )}
              </div>
            ))}

            <div>
              <div className="flex flex-row justify-between w-full px-7 mt-3">
                <div className="w-[15%]" />
                <p className="text-center text-[18px] font-bold">Sign Here</p>

                <button
                  className="text-blue-500 flex cursor-pointer"
                  onClick={() => {
                    clear();
                    setIsSignaturePresent(false);
                  }}
                >
                  <p className="mt-auto lg:text-[14px] text-[10px]">Clear</p>
                </button>
              </div>

              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  className:
                    "mx-auto md:h-[280px] h-[140px] border w-[90%] rounded-md shadow-[0_0px_60px_2px_rgba(0,0,0,0.05)] lg:mt-5 mt-5",
                }}
                ref={signatureCanvasRef}
                onEnd={() => {
                  if (signatureCanvasRef.current) {
                    let img = signatureCanvasRef.current.toDataURL();
                    setFieldValue("signature", dataURLtoFile(img, "signature.png"));
                    setOnSubmit(dataURLtoFile(img, "signature.png"));
                    setIsSignaturePresent(true);
                  }
                }}
              />
              {errors.signature && touched.signature && (
                <p className="text-red-600 text-[15px]">{errors.signature}</p>
              )}
            </div>
            <div className="flex items-center lg:mt-[10px] mt-[15px] justify-center p-6 rounded-b">
              <button
                className={`${isSignaturePresent ? "bg-Appcolor" : "bg-gray-400"
                  } text-white font-medium px-2 py-3 rounded-md w-[240px] text-sm`}
                type="submit"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const userForm = () => {
    return (
      <Formik
        initialValues={{
          signature: null,
          fullName: `${user.first_name} ${user.last_name}`,
          address: user.address1,
          city: user.city,
          state: user.state,
          zip: user.zip,
          phone: user.phone,
          dob: "",
          todayDate: moment(new Date()).format("MM/DD/YYYY"),
        }}
        validationSchema={validationSchemaUser}
        onSubmit={(values) => handleSubmissionUser(values)}
      >
        {({ errors, touched, handleBlur, values, setFieldValue, handleChange }) => (
          <Form>
            <div className="relative z-0 w-full mb-5 group mt-4">
              <input
                type="text"
                name="fullName"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none font-semibold dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                disabled
                value={values.fullName}
              />
              <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                Parent name/legal guardian (print):
              </label>
            </div>

            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="address"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none font-semibold dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                disabled
                value={values.address}
              />
              <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                Address
              </label>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="city"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.city}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  City
                </label>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="state"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.state}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  State
                </label>
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="zip"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.zip}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  Zip
                </label>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="tel"
                  name="phone"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.phone}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  Phone number
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="date"
                  name="dob"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  value={values.dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.dob && touched.dob && (
                  <div className="text-red-500 text-sm">{errors.dob}</div>
                )}
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  Date of birth
                </label>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="todayDate"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 font-semibold border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  disabled
                  value={values.todayDate}
                />
                <label className="peer-focus:font-medium absolute text-gray-500 dark:text-gray-700 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]">
                  Today's date
                </label>
              </div>
            </div>

            <div>
              <div className="flex flex-row justify-between w-full px-7 mt-3">
                <div className="w-[15%]" />
                <p className="text-center text-[18px] font-bold">Sign Here</p>

                <button
                  className="text-blue-500 flex cursor-pointer"
                  onClick={() => {
                    clear();
                    setIsSignaturePresent(false);
                  }}
                >
                  <p className="mt-auto lg:text-[14px] text-[10px]">Clear</p>
                </button>
              </div>

              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  className:
                    "mx-auto md:h-[280px] h-[140px] border w-[90%] rounded-md shadow-[0_0px_60px_2px_rgba(0,0,0,0.05)] lg:mt-5 mt-5",
                }}
                ref={signatureCanvasRef}
                onEnd={() => {
                  if (signatureCanvasRef.current) {
                    let img = signatureCanvasRef.current.toDataURL();
                    setFieldValue("signature", dataURLtoFile(img, "signature.png"));
                    setOnSubmit(dataURLtoFile(img, "signature.png"));
                    setIsSignaturePresent(true);
                  }
                }}
              />
              {errors.signature && touched.signature && (
                <p className="text-red-600 text-[15px]">{errors.signature}</p>
              )}
            </div>
            <div className="flex items-center lg:mt-[10px] mt-[15px] justify-center p-6 rounded-b">
              {
                loader ?
                  <Spinner className="spinner" isLoading={loader} />
                  :
                  (
                    <button
                      className={`${isSignaturePresent ? "bg-Appcolor" : "bg-gray-400"
                        } text-white font-medium px-2 py-3 rounded-md w-[240px] text-sm`}
                      type="submit"
                    >
                      Submit
                    </button>
                  )
              }

            </div>
          </Form>
        )}
      </Formik>
    );
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50   ">
            <div className="lg:w-[50%] fixed p-5  px-20 mx-auto">
              <div className="border-0 rounded-lg shadow-lg relative md:h-full h-[450px] flex flex-col w-full bg-white outline-none focus:outline-none ">
                <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t-lg ">
                  <h3 className="lg:text-[18px] text-[14px] w-full flex justify-center mx-auto text-white font-medium">
                    Parental Agreement
                  </h3>

                  <button
                    className="w-0 h-2 rounded-2xl lg:mt-[-35px] mt-[-32px] lg:mr-[-2px] mr-[-4px]"
                    onClick={() => {
                      {
                        isFromSummary ? (
                          <>
                            {clear()}
                            {setShowModal(false)}
                          </>
                        ) : (
                          setShowModal(false)
                        );
                      }
                    }}
                  >
                    <span className="lg:w-[34px] lg:h-[32px] w-[27px] h-[25px]  block  ">
                      <img src={Close} />
                    </span>
                  </button>
                </div>
                <div className="w-[90%] mx-auto custom-scrollbar xl:h-[600px] md:h-[400px] ">
                  <div className="lg:w-[90%] w-[95%]   mx-auto">
                    <p className="font-bold mt-[20px] lg:text-[20px] text-[12px] text-Appcolor">
                      Agreement
                    </p>
                    <div className="flex justify-between">
                      <div className="lg:text-[14px]   text-[10px]  mt-[18px] w-full text-[Textlitecolor]">
                        {showFullText ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: responseData?.content ?? "",
                              }}
                            ></div>
                            <button
                              className="text-blue-500 flex cursor-pointer"
                              type="button"
                              onClick={toggleReadMore}
                            >
                              <br />
                              <p className=" lg:mt-[30px] mt-[15px] ">
                                {" "}
                                Read Less...
                              </p>
                            </button>
                          </>
                        ) : (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  responseData?.content?.length > 400
                                    ? responseData?.content.substr(0, 400) +
                                    " ..."
                                    : responseData?.content,
                              }}
                            ></div>
                            <button
                              className="text-blue-500 flex cursor-pointer"
                              type="button"
                              onClick={toggleReadMore}
                            >
                              <br />
                              <p className="lg:mt-[30px] mt-[15px]">
                                Read More
                              </p>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    {is_18_select ? minorForm() : userForm()}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

const MINORS = [
  {
    title: "MINOR NAME #1",
    fields: [
      { id: "first_name_1", label: "First Name", type: "text", required: true },
      { id: "last_name_1", label: "Last Name", type: "text", required: true },
      { id: "dob_1", label: "Date of Birth", type: "date", required: true },
      { id: "relation_1", label: "Enter Relation", type: "text", required: true }
    ]
  },
  {
    title: "MINOR NAME #2",
    fields: [
      { id: "first_name_2", label: "First Name", type: "text", required: false },
      { id: "last_name_2", label: "Last Name", type: "text", required: false },
      { id: "dob_2", label: "Date of Birth", type: "date", required: false },
      { id: "relation_2", label: "Enter Relation", type: "text", required: false }
    ]
  },
  {
    title: "MINOR NAME #3",
    fields: [
      { id: "first_name_3", label: "First Name", type: "text", required: false },
      { id: "last_name_3", label: "Last Name", type: "text", required: false },
      { id: "dob_3", label: "Date of Birth", type: "date", required: false },
      { id: "relation_3", label: "Enter Relation", type: "text", required: false }
    ]
  },
  {
    title: "MINOR NAME #4",
    fields: [
      { id: "first_name_4", label: "First Name", type: "text", required: false },
      { id: "last_name_4", label: "Last Name", type: "text", required: false },
      { id: "dob_4", label: "Date of Birth", type: "date", required: false },
      { id: "relation_4", label: "Enter Relation", type: "text", required: false }
    ]
  },
];