import React, { useState, useEffect, useRef } from "react";
import Setting from "../assets/Setting.svg";
import Handshake from "../assets/handshake.svg";
import Creditcard from "../assets/creditcard.svg";
import Book from "../assets/book.svg";
import Slideshow from "../assets/slideshow.svg";
import Hamburger from "../assets/hamburger.svg";
import Close from "../assets/close.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { Aggrementmodal } from "../components/Modals/Aggrementmodal";
import { Steps } from "../components/Modals/Steps";
import { Howitwork } from "./Modals/Howitworkmodal";
import Logo from "../../src/assets/Png/logo.png";
import BigLogo from "../../src/assets/Biglogo.svg";
import Modal from "./Modals";
import Home from '../assets/homeicon.svg'
import HelpSupport from '../assets/helpsupport.svg'
import Calender from '../assets/calender.svg'
import { get } from "../utils/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setExtraCart } from "../state";
import { useDispatch, useSelector } from "react-redux";


const Sidenav = (props) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [showHowitworkmodal, setShowhowitworkmodal] = useState(false);
   const [showModal, setShowmodal] = useState(false);
   const [showStepsmodal, setShowstepsmodal] = useState(false);
   const [signature, setSignature] = useState(null);
   const [activeComponent, setActiveComponent] = useState(null);
   const [selectedIndex, setSelectedIndex] = useState(props.selectedOne);
   const [responseData, setResponseData] = useState("");
   const userType = localStorage.getItem("user_type")
   const [open, setOpen] = useState(false);
   const useJourneyVendor = useSelector((state) => state.useJourneyVendor);
   const vandor = useSelector((state) => state.selectedVendor);

   const user = useSelector((state) => state.user);


   const innerRef = useRef(null); // Define innerRef using useRef

   useEffect(() => {
      getBookings()
   }, [])

   const getBookings = () => {
      let data = userType === "property-owner" ? "property-owner" : "guest";
      get(`/agreement/${data}`)
         .then((res) => {
            const response = res?.response?.data?.agreement;
            if (response) {
               setResponseData(response);
            } else {
               alert("Error");
            }
         })
         .catch((err) => {
            toast.error(err?.response?.data?.error?.messages[0], {
               position: toast.POSITION.BOTTOM_RIGHT,
            });
         })
   };



   const handleHomeClick = () => {
      navigate("/home");
      if (!useJourneyVendor && !user.user_selected_vendor_id) {
         if (props.innerRef && typeof props.innerRef.current.updateState === "function") {
            props.innerRef.current.updateState();
         }
      }
      else {
         if (props.innerRef && typeof props.innerRef.current.updateState === "function") {
            props.innerRef.current.updateStateSelectedVendor();
         }
      }

   };



   const guestData = [
      {
         onClick: "/home",
         name: "Home",
         img: <img src={Home} />,
      },
      {
         onClick: "/CompareVendor",
         name: "Vendor Comparison",
         img: <img src={require('../assets/Png/icon_comparison.png')} alt="vendor_comparison" className="w-6 h-6" style={{ color: 'red', fill: 'red' }} />,
      },
      {
         onClick: () => setShowhowitworkmodal(true),
         name: "LSV Safety Video",
         img: <img src={Slideshow} alt="Slideshow image" />,
      },
      {
         onClick: () => setShowstepsmodal(true),
         name: "Reservation Guide",
         img: <img src={Book} alt="Book image" />,
      },
      {
         onClick: "/reservations",
         name: "My Reservations",
         img: <img src={Calender} alt="set image" />,
      },
      {
         onClick: () => setActiveComponent("A"),
         name: "My Cards",
         img: <img src={Creditcard} alt="Creditcard image" />,
      },
      {
         onClick: "/Helpsupport",
         name: "Help & Support",
         img: <img src={HelpSupport} alt="set image" />,
      },
      {
         onClick: "/settings",
         name: "Settings",
         img: <img src={Setting} alt="set image" />,
      },
   ];
   if (user.default_vendor !== null) {
      const newItem = {
         onClick: "/ContactVendor",
         name: "LSV Rental Support",
         img: <img src={require('../assets/icon_talking.png')} alt="vendor_comparison" className="w-6 h-6"/>,
      };
      guestData.splice(guestData.length - 1, 0, newItem);
   }
   const ownerData = [
      {
         onClick: "/vendors",
         name: "Home",
         img: <img src={Home} />,
      },
      {
         onClick: "/Helpsupport",
         name: "Help & Support",
         img: <img src={HelpSupport} alt="set image" />,
      },
      {
         onClick: "/settings",
         name: "Settings",
         img: <img src={Setting} alt="set image" />,
      },
   ];

   useEffect(() => {

   }, [userType])



   const toggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleItemClick = (onClickFunction) => {
      if (typeof onClickFunction === "function") {
         onClickFunction();
      } else {
         navigate(onClickFunction);
      }
      setOpen(false);
   };

   useEffect(() => {
   }, [open]);


   return (
      <>
         <div className=" fixed  z-50 flex items-end justify-end">
            <span
               className={`fixed sm:block lg:hidden w-6 h-6 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl duration-300  
            ${open ? "left-[100px] z-10 top-0 " : "left-[0px] top-0 "}`}
               onClick={toggle}
            >
               <p className="w-8 h-8">
                  {open ? (
                     <img
                        src={Close}
                        alt="Close Navigation"
                        className="md:hidden block  mt-1 z-10"
                     />
                  ) : (
                     <img
                        src={Hamburger}
                        alt="Open Navigation"
                        className="md:hidden   inset-0  fixed left-2 block  sm:mt-0   w-8 h-8"
                     />
                  )}
               </p>
            </span>

            <div className={`${open ? "w-32" : "w-0"} 2xl:w-40 xl:w-[120px] md:w-28 bg-Appcolor relative duration-500`} >
               <ul className="pt-6 custom-scrollbar min-h-[calc(100vh-0px)] max-h-[calc(100vh-0px)]">
                  {
                     userType === "guest" ?
                        <li className="flex justify-center items-center cursor-pointer mb-5">
                           <img
                              src={BigLogo}
                              alt="Big Logo"
                              className="lg:block hidden object-contain mx-auto w-[90%] h-9 mt-[-10px]" onClick={() => navigate("/home")} />
                           <img
                              src={Logo}
                              alt="Small Logo"
                              className="lg:hidden block justify-center object-contain w-[100%] h-16"
                              onClick={() => navigate("/home")} />
                        </li>
                        :
                        <li className="flex justify-center items-center cursor-pointer mb-5">
                           <img
                              src={BigLogo}
                              alt="Big Logo"
                              className="lg:block hidden object-contain mx-auto w-[90%] h-9 mt-[-10px]" onClick={() => navigate("/vendors")} />
                           <img
                              src={Logo}
                              alt="Small Logo"
                              className="lg:hidden block justify-center object-contain w-[100%] h-16"
                              onClick={() => navigate("/vendors")} />
                        </li>
                  }
                  {userType === "guest" && guestData.map((items, ind) => (
                     <div key={ind} activeClassName="selected" onClick={() => {
                        if (items.name === "Home" && !useJourneyVendor && user.user_selected_vendor_id) {
                           props?.innerRef?.current.updateState()
                           handleHomeClick()
                        }
                        else {
                           props?.innerRef?.current.updateStateSelectedVendor()
                        }
                     }}>

                        <li
                           className={`flex flex-col rounded-md lg:py-4 py-[10px] cursor-pointer hover:bg-blue-700 text-white text-sm items-center gap-x-4 ${selectedIndex == ind ? "selected" : ""}`}
                           onClick={() => {
                              dispatch(setExtraCart([]))
                              setSelectedIndex(ind)
                              handleItemClick(items.onClick)

                              if (typeof items.onClick === "function") {
                                 items.onClick();
                              } else {
                                 navigate(items.onClick, { selectedIndex });
                              }
                           }}
                        >
                           {items.img}
                           <span
                              className={`${open ? "block" : "md:block hidden "
                                 } lg:text-[14px] md:text-[13px] text-[13px] w-[70%] mx-auto font-medium text-center lg:pt-[10px] pt-[8px]`}
                           >
                              {items.name}
                           </span>
                        </li>
                     </div>
                  ))}
                  {userType === "property-owner" && ownerData.map((items, ind) => (
                     <div key={ind} activeClassName="selected">
                        <li
                           className={`flex flex-col rounded-md lg:py-4 py-[10px] cursor-pointer hover:bg-blue-700 text-white text-sm items-center gap-x-4 ${selectedIndex == ind ? "selected" : ""
                              }`}
                           onClick={() => {
                              setSelectedIndex(ind)
                              handleItemClick(items.onClick)
                              if (typeof items.onClick === "function") {
                                 items.onClick();
                              } else {
                                 navigate(items.onClick, { selectedIndex });
                              }
                           }}
                        >
                           {items.img}
                           <span className={`${open ? "block" : "md:block hidden "} lg:text-[14px] md:text-[13px] text-[13px] w-[70%] mx-auto font-medium text-center lg:pt-[10px] pt-[8px]`} >
                              {items.name}
                           </span>
                        </li>
                     </div>
                  ))}
               </ul>
            </div>
         </div>
         <Howitwork
            showHowitworkmodal={showHowitworkmodal}
            setShowhowitworkmodal={setShowhowitworkmodal}
         />
         <Aggrementmodal
            showModal={showModal}
            setShowModal={setShowmodal}
            agreementData={responseData}
            isFromSignup={false}
            setSignature={setSignature}
         />
         <Steps
            showStepsmodal={showStepsmodal}
            setShowstepsmodal={setShowstepsmodal}
         />

         <Modal
            SummaryPage={false}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
         />
         <ToastContainer limit={1} />
      </>
   );
};

export default Sidenav;
