
import React, { useState } from 'react';
// import Vehicle from '../../assets/Vehicle.svg'
// import Inputfield from '../../components/Inputfiled';
import Sidenav from '../Sidenav';
import Card from './card';
import Avatar from '../../assets/avatar.svg';
import { Logout } from '../Modals/Logout';
import { useSelector } from 'react-redux';

const ContactVendor = () => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const user = useSelector((state) => state.user);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className="w-[100%] flex   flex-row">
            <div className=" lg:w-[9%] md:w-[12%]  ">
                <Sidenav  />
            </div>
            <div className="w-[100%]  flex flex-col ">
                <div className='flex items-center md:w-[95%] justify-end md:my-[10px] my-0 mx-5  cursor-pointer' onClick={toggleDropdown}>
                    <img className='my-[6px] md:my-[4px] md:h-8  h-6 cursor-pointer ' src={Avatar} />
                    <p className="pl-3 font-bold">{user.first_name} {user.last_name}</p>
                </div>
                <Card />
            </div>
            <Logout isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />
        </div>
    );
};

export default ContactVendor;
