import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { get, post } from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { setUseAgreement } from "../../state";
import Spinner from "../Spinner";
import Close from "../../assets/Png/Closemodal.png";
import moment from "moment";

export function SummaryAggrementmodal({
  setAgreementCheck,
  showModal,
  setShowModal,
  setSignature,
  isFromSummary,
  onResponseSignatureData,
  onResponseData,
  summaryData
}) {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.token);
  const userData = useSelector((state) => state.user);
  const [showFullText, setShowFullText] = useState(false);
  const [signatureSubmitted, setSignatureSubmitted] = useState(false);
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const storedAddress2 = useSelector((state) => state.address);
  const [onsubmit, setOnSubmit] = useState(null);
  const [responseData, setResponseData] = useState(summaryData.agreement);
  const [loader, setLoader] = useState(false);
  const [isSignaturePresent, setIsSignaturePresent] = useState(false);
  const [responseSignatureData, setResponseSignatureData] = useState();
  const [setImage, setShowImage] = useState(null);
  const signatureCanvasRef = useRef(null);
  console.log("responseData==>",responseData);
  
  const difference = JSON.parse(localStorage.getItem("daysDifference"));
  const daysDifference = difference + 1;

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  onResponseSignatureData(responseSignatureData);
  onResponseData(responseData);

  const clear = () => {
    signatureCanvasRef.current.clear();
  };

  const SignatureDataResponse = localStorage.getItem("apiData");

  const handleSubmission = async () => {
    try {
      setLoader(true);

      if (signatureCanvasRef.current?.isEmpty()) {
        // console.log("Signature is required");
        return;
      }

      const img = signatureCanvasRef.current.toDataURL();
      const signatureFile = dataURLtoFile(img, "signature.png");

      const formdata = new FormData();
      formdata.append("signature", signatureFile);

      const res = await post(`/booking/upload-signature`, formdata, {}, Token);

      if ("response" in res) {
        const response = res.response.data.signature;

        setResponseSignatureData(response);
        setSignatureSubmitted(true);
      }

      if (loader) {
        return (
          <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
            <Spinner className="spinner" isLoading={loader} />
          </div>
        );
      } else {
        setShowModal(false);
        setAgreementCheck(true);
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoader(false);
    }
  };

  // const user_selected_vendor_id = useSelector(
  //   (state) => state.user_selected_vendor_id
  // );
  // const vendorId = selectedVendor?.id ?? user_selected_vendor_id;

  // useEffect(() => {
  //   setLoader(true);
  //   let start = localStorage.getItem("startDate");
  //   let end = localStorage.getItem("endDate");
  //   var formdata = new FormData();
  //   formdata.append("days", daysDifference);
  //   formdata.append("vendor_id", vendorId);
  //   formdata.append("address_id", storedAddress2.id);
  //   formdata.append("start_date", moment(start, "MM/DD/YYYY").format("YYYY-MM-DD"));
  //   formdata.append("end_date", moment(end, "MM/DD/YYYY").format("YYYY-MM-DD"));
  //   post(`/booking/sync-data`, formdata, {}, Token)
  //     .then((res) => {
  //       if ("response" in res) {
  //         setLoader(false);

  //         const response = res?.response?.data?.agreement;
  //         setResponseData(response);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //     });
  // }, []);

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50   ">
            <div className="lg:w-[50%] fixed p-5  px-20 mx-auto">
              <div className="border-0 rounded-lg shadow-lg relative md:h-full h-[450px] flex flex-col w-full bg-white outline-none focus:outline-none ">
                <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t-lg ">
                  <h3 className="lg:text-[18px] text-[14px] w-full flex justify-center mx-auto text-white font-medium">
                    Agreement
                  </h3>

                  <button
                    className="w-0 h-2 rounded-2xl lg:mt-[-35px] mt-[-32px] lg:mr-[-2px] mr-[-4px]"
                    onClick={() => {
                      {
                        isFromSummary ? (
                          <>
                            {clear()}
                            {setShowModal(false)}
                          </>
                        ) : (
                          setShowModal(false)
                        );
                      }
                    }}
                  >
                    <span className="lg:w-[34px] lg:h-[32px] w-[27px] h-[25px]  block  ">
                      <img src={Close} />
                    </span>
                  </button>
                </div>
                <div className="w-[90%] mx-auto custom-scrollbar xl:h-[600px] md:h-[400px] ">
                  <div className="lg:w-[90%] w-[95%]   mx-auto">
                    <p className="font-bold mt-[20px] lg:text-[20px] text-[12px] text-Appcolor">
                      Vendor Agreement
                    </p>
                    <div className="flex justify-between">
                      <div className="lg:text-[14px]   text-[10px]  mt-[18px] w-full text-[Textlitecolor]">
                        {showFullText ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: responseData?.content ?? "",
                              }}
                            ></div>
                            <button
                              className="text-blue-500 flex cursor-pointer"
                              type="button"
                              onClick={toggleReadMore}
                            >
                              <br />
                              <p className=" lg:mt-[30px] mt-[15px] ">
                                {" "}
                                Read Less...
                              </p>
                            </button>
                          </>
                        ) : (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  responseData?.content?.length > 400
                                    ? responseData?.content.substr(0, 400) +
                                    " ..."
                                    : responseData?.content,
                              }}
                            ></div>
                            <button
                              className="text-blue-500 flex cursor-pointer"
                              type="button"
                              onClick={toggleReadMore}
                            >
                              <br />
                              <p className="lg:mt-[30px] mt-[15px]">
                                Read More
                              </p>
                            </button>
                          </>
                        )}
                      </div>
                      {isFromSummary && (
                        <div className="mt-auto">
                          <button
                            className="text-blue-500 flex cursor-pointer "
                            onClick={() => {
                              clear();
                              setIsSignaturePresent(false);
                            }}
                          // onClick={() => { clear() }}
                          >
                            <br />
                            <p className="lg:mt-[30px] mt-auto lg:text-[14px]   text-[10px]">
                              Clear
                            </p>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="">
                    {isFromSummary ? (
                      <div >
                        <p className="text-center text-[18px] font-bold">Sign Here</p>
                        <SignatureCanvas
                          penColor="black"
                          canvasProps={{
                            className:
                              "mx-auto md:h-[280px] h-[140px] border w-[90%] rounded-md shadow-[0_0px_60px_2px_rgba(0,0,0,0.05)] lg:mt-5 mt-5",
                          }}
                          ref={signatureCanvasRef}
                          onEnd={() => {
                            if (signatureCanvasRef.current) {
                              let img = signatureCanvasRef.current.toDataURL();
                              setOnSubmit(dataURLtoFile(img, "signature.png"));
                              setIsSignaturePresent(true);
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="w-[90%] mx-auto flex justify-center items-center mt-2">
                        <img
                          draggable={false}
                          src={SignatureDataResponse}
                          alt="Signature Image"
                          className="w-[50%] h-[50%]"
                        />
                      </div>
                    )}
                  </div>

                  {isFromSummary ? (
                    // <div className="flex items-center lg:mt-[10px] mt-[15px] justify-center p-6 rounded-b">
                    //     <button
                    //         className={`${isSignaturePresent ? "bg-Appcolor" : "bg-gray-400"
                    //             }  text-white font-medium px-2 py-3 rounded-md w-[240px] text-sm`}
                    //         // className={`bg-Appcolor text-white font-medium px-2 py-3 rounded-md w-[240px] text-sm`}

                    //         type="button"
                    //         disabled={!isSignaturePresent}
                    //         onClick={() => {
                    //             {
                    //                 loader === true ?

                    // <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
                    //     <Spinner className="spinner" isLoading={loader} />
                    // </div>
                    //                     :
                    //                     setAgreementCheck(true);

                    //                 handleSubmit();
                    //                 setShowModal(false);
                    //             }
                    //         }}
                    //     >
                    //         Submit
                    //     </button>
                    // </div>

                    <div className="flex items-center lg:mt-[10px] mt-[15px] justify-center p-6 rounded-b">
                      <button
                        className={`${isSignaturePresent ? "bg-Appcolor" : "bg-gray-400"
                          } text-white font-medium px-2 py-3 rounded-md w-[240px] text-sm`}
                        type="button"
                        disabled={!isSignaturePresent || loader}
                        onClick={handleSubmission}
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
  // }
}
