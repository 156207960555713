import React from 'react';
import Avatar from '../../assets/avatar.svg'; // Replace with actual avatar path
import { useSelector } from 'react-redux';

const StepNav = ({ steps, currentStep, toggleDropdown }) => {
  const user = useSelector((state) => state.user);

  return (
    <div className="w-full px-4 pt-4 ">
      {/* Stepper and Logout section in a single row */}
      <div className="flex justify-between items-center">
        {/* Stepper Section */}
        <ol className="flex items-center space-x-8 rtl:space-x-reverse mx-4">
          {steps.map((step, index) => (
            <li
              key={index}
              className={`flex items-center space-x-2.5 rtl:space-x-reverse 
                ${currentStep >= index + 1 ? 'text-blue-600 dark:text-blue-500' : 'text-gray-500 dark:text-gray-400'}`}
            >
              <span
                className={`flex items-center justify-center w-8 h-8 border rounded-full shrink-0  font-bold
                  ${currentStep >= index + 1 ? 'border-blue-600 dark:border-blue-500' : 'border-gray-500 dark:border-gray-400'}`}
              >
                {index + 1}
              </span>
              <span>
                <h3 className="font-bold leading-tight">{step.title}</h3>
                <p className="text-sm">{step.description}</p>
              </span>
            </li>
          ))}
        </ol>

        {/* Logout Section */}
        <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
          <img className="h-6 cursor-pointer" src={Avatar} alt="Avatar" />
          <p className="pl-3 font-bold md:text-[16px] text-[13px]">
            {user?.first_name} {user?.last_name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepNav;
